window.onload = function() {
    new CookieMessage();
};

class CookieMessage {
    constructor() {
        this.cookieBox = document.getElementsByClassName('md-cookie-message');
        this.acceptButton = document.getElementsByClassName('md-cookie-accept');
        this.restoreButton = document.getElementsByClassName('md-cookie-restore');

        for (var i = 0; i < this.cookieBox.length; i++) {
            var element = this.cookieBox[i];
            var cookieName = element.getAttribute('data-target');

            var cookie = this.getCookie(cookieName);
            if (cookie === '') {
                element.style.display = 'inherit';
            } else {
                element.style.display = 'none';
            }
        }

        for (var i = 0; i < this.restoreButton.length; i++) {
            var element = this.restoreButton[i];
            var cookieName = element.getAttribute('data-target');

            var cookie = this.getCookie(cookieName);
            if (cookie === '') {
                element.style.display = 'none';
            } else {
                element.style.display = 'inherit';
            }
        }

        var self = this;
        for (var i = 0; i < this.acceptButton.length; i++) {
            this.acceptButton[i].onclick = function() {
                var CookieDate = new Date;
                var cookieName = this.getAttribute('data-target');
                CookieDate.setFullYear(CookieDate.getFullYear() + 1);
                document.cookie = cookieName + '=1; expires=' + CookieDate.toGMTString() + '; path=/';

                for (var u = 0; u < self.cookieBox.length; u++) {
                    var element = self.cookieBox[u];
                    var cookieNameBox = element.getAttribute('data-target');

                    if (cookieName === cookieNameBox) {
                        element.style.display = 'none';
                    }
                }

                for (var u = 0; u < self.restoreButton.length; u++) {
                    var element = self.restoreButton[u];
                    var cookieNameBox = element.getAttribute('data-target');

                    if (cookieName === cookieNameBox) {
                        element.style.display = 'inherit';
                    }
                }
            };
        }

        var self = this;
        for (var i = 0; i < this.restoreButton.length; i++) {
            this.restoreButton[i].onclick = function() {
                var CookieDate = new Date;
                var cookieName = this.getAttribute('data-target');
                CookieDate.setFullYear(CookieDate.getFullYear() - 1);
                document.cookie = cookieName + '=1; expires=' + CookieDate.toGMTString() + '; path=/';

                for (var u = 0; u < self.cookieBox.length; u++) {
                    var element = self.cookieBox[u];
                    var cookieNameBox = element.getAttribute('data-target');

                    if (cookieName === cookieNameBox) {
                        element.style.display = 'inherit';
                    }
                }

                for (var u = 0; u < self.restoreButton.length; u++) {
                    var element = self.restoreButton[u];
                    var cookieNameBox = element.getAttribute('data-target');

                    if (cookieName === cookieNameBox) {
                        element.style.display = 'none';
                    }
                }
            };
        }
    }

    getCookie(cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}