
/************
 Multi Digital
 ************/


// LightGallery
import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';

// OWL Carousel
import 'owl.carousel/dist/owl.carousel.min.js';


$(document).ready(function() {

    // Lazy Load
    $('.lazy').lazy({
        effect: "fadeIn", 
        effectTime: 1000,
        threshold: 500,
    });

    // Preloader
    $(window).on("load",function () {
        var preloaderDelay = 400,
            preloaderFadeOutTime = 1200;

        function hidePreloader() {
            var loadingAnimation = $('#loader'),
                preloader = $('.loader-circle');

            loadingAnimation.fadeOut();
            preloader.delay(preloaderDelay).fadeOut(preloaderFadeOutTime);
        }
        hidePreloader();
    });

    // Navigation
    $('.toggle-menu').jPushMenu({
             closeOnClickOutside: false,
             closeOnClickLink: false
         }
    );


    // Leistungen - Carousel 
    $('#leistungen_home .owl-carousel').owlCarousel({
        loop:true,
        margin:30,
        dots:false,
        nav:true,
        grabCursor: !0,
        autoplay:false,
        slidesPerView: "auto",
        smartSpeed: 500,
        autoplayTimeout:2000,
        navRewind:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    })


    // Pfeile
    $('.owlCarousel .owl-prev').hide();
    $( ".owl-prev").html('<i class="fal fa-chevron-left"></i>');
    $( ".owl-next").html('<i class="fal fa-chevron-right"></i>');

    // Tabs
    // $('.tabs a').on('click', function (event) {
    //     event.preventDefault()
    //     $(this).tab('show')
    // })

    // Navigation up/down
    $('.accordion').on('show.bs.collapse', function (e) {
        $("#" + e.target.id).prev().find("i").removeClass("fa-angle-down").addClass("fa-angle-up");
    });

    $('.accordion').on('hide.bs.collapse', function (e) {
        $("#" + e.target.id).prev().find("i").removeClass("fa-angle-up").addClass("fa-angle-down");
    });
    
    // Light Gallery
    var elements = document.getElementsByClassName('lightgallery');

    for (var i = 0; i < elements.length; i++) {
        lightGallery(elements[i], {
            selector: '.item',
            download: false,
            thumbnail: false,
            autoplayControls: true,
            autoplay: false,
            zoom:true,
            hash: true,
            share:false,
            fullScreen: true,
            licenseKey: "BC0281CA-B5024C44-94CFC10D-48F52900"
        });
    }
    
    
});


