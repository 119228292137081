import $ from "jquery";
import 'bootstrap';

// AOS
import AOS from 'aos';
AOS.init()

// Cooke Message 
import './mdCookieMessage.js'

// Lazy
import 'jquery-lazy';

// jPush Menu
import './external/jPushMenu.js';


// MD JS
import './multidigital.js';
